'use client'

import * as React from 'react'

interface Params {
  data: unknown
}

export default function JsonLd({ data }: Params): React.ReactElement | null {
  if (!data) {
    return null
  }

  return (
    /* eslint-disable react/no-danger,react-perf/jsx-no-new-object-as-prop */
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
    /* eslint-enable react/no-danger,react-perf/jsx-no-new-object-as-prop */
  )
}
