'use client'

import * as React from 'react'
import Image from 'next/image'
import { Oooh_Baby } from 'next/font/google'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightLong } from '@fortawesome/free-solid-svg-icons'

const ooohBaby = Oooh_Baby({
  weight: ['400'],
  subsets: ['latin'],
  display: 'swap',
})

export default function HomePageStyles(): React.ReactElement {
  return (
    <section className="bg-neutral-100 py-16 dark:bg-neutral-800">
      <div className="mx-auto flex w-full max-w-screen-xl flex-col justify-center">
        <div className="mx-4 mb-4 flex flex-col items-center sm:mx-8 md:mx-12 lg:mx-20 xl:mx-32">
          <h3 className="text-primary-text dark:text-primary-text-dark mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-3xl lg:text-4xl">
            Guide the AI with your own image
          </h3>
          <h4 className="text-secondary-text sm:text-md dark:text-secondary-text-dark mb-4 text-sm font-normal tracking-wide md:text-lg lg:text-xl">
            Be faithful or creative with your transformations
          </h4>
          <span className="text-disabled-text dark:text-disabled-text-dark mb-8 text-[10px] font-normal tracking-wide">
            &laquo; The following images have all been generated using our models &raquo;
          </span>
        </div>
        <div className="mb-12">
          <div className="flex flex-col items-center justify-center gap-4 sm:gap-8 lg:flex-row">
            <div className="relative flex flex-col items-center gap-2 lg:flex-row">
              <span className={`${ooohBaby.className} mr-2 text-lg sm:text-3xl lg:rotate-180 lg:[writing-mode:vertical-lr]`}>
                Your image guide
              </span>
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <Image
                src="/images/examples/image-guide-family-photo-thumb.png"
                alt="Family photo"
                width={300}
                height={300}
                className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark size-[200px] rounded-lg border sm:size-[300px]"
              />
              <FontAwesomeIcon
                icon={faRightLong}
                className="text-disabled-text dark:text-secondary-text-dark mt-4 size-4 rotate-90 sm:mt-8 sm:size-8 lg:ml-8 lg:mt-0 lg:rotate-0"
              />
            </div>
            <div className="flex flex-col-reverse gap-4 lg:flex-row">
              <div className="grid grid-cols-2 gap-4 sm:gap-8">
                <div className="flex flex-col items-center gap-2">
                  <span className={`${ooohBaby.className} text-xl`}>Family photo</span>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  <Image
                    src="/images/examples/image-guide-family-photo-edges-result-faithful-thumb.png"
                    alt="Family photo edges result"
                    width={200}
                    height={200}
                    className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark size-[150px] rounded-lg border sm:size-[200px]"
                  />
                </div>
                <div className="flex flex-col items-center gap-2">
                  <span className={`${ooohBaby.className} text-xl`}>Robots</span>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  <Image
                    src="/images/examples/image-guide-family-photo-depth-result-creative-thumb.png"
                    alt="Family photo depth map result"
                    width={200}
                    height={200}
                    className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark size-[150px] rounded-lg border sm:size-[200px]"
                  />
                </div>
                <div className="flex flex-col items-center gap-2">
                  <span className={`${ooohBaby.className} text-xl`}>Dinosaurs</span>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  <Image
                    src="/images/examples/image-guide-family-photo-edges-result-creative-thumb.png"
                    alt="Family photo edges result"
                    width={200}
                    height={200}
                    className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark size-[150px] rounded-lg border sm:size-[200px]"
                  />
                </div>
                <div className="flex flex-col items-center gap-2">
                  <span className={`${ooohBaby.className} text-xl`}>Raccoons</span>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  <Image
                    src="/images/examples/image-guide-family-photo-soft-edges-result-creative-thumb.png"
                    alt="Family photo soft edges result"
                    width={200}
                    height={200}
                    className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark size-[150px] rounded-lg border sm:size-[200px]"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className={`${ooohBaby.className} mb-4 text-lg sm:text-3xl lg:mb-0 lg:mt-8 lg:[writing-mode:vertical-lr]`}>
                  Transformation examples with <span className="font-extrabold">Pixar</span> style
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
