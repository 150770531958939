'use client'

import * as React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { Oooh_Baby } from 'next/font/google'
import Cta from '@/app/components/Cta'
import Styles from './components/Styles'
import { NUM_FREE_DAILY_CREDITS } from '../../../../../../../lib/constants/lib'
import ImageGuides from './components/ImageGuides'
// import Carousel from './components/Carousel'

const ooohBaby = Oooh_Baby({
  weight: ['400'],
  subsets: ['latin'],
  display: 'swap',
})

export default function HomePage(): React.ReactElement {
  return (
    <div className="mt-24 flex flex-col justify-center gap-8">
      <div className="mx-auto flex w-full max-w-screen-xl flex-col items-center gap-16 md:flex-row md:justify-evenly md:px-16 xl:justify-between">
        <div className="flex flex-col items-center gap-2 md:px-2">
          <h2 className="text-primary-text dark:text-primary-text-dark mb-4 text-center text-4xl font-extrabold leading-none tracking-tight sm:mb-8 sm:text-6xl md:mb-4 md:text-4xl xl:mb-8 xl:text-6xl">
            PicTrix
          </h2>
          <h1 className="text-primary-text dark:text-primary-text-dark text-center text-xl font-extrabold leading-none tracking-tight sm:text-3xl md:text-xl xl:text-3xl">
            Text to Image and Color SVG AI Art Generator
          </h1>
          <h4 className="text-secondary-text dark:text-secondary-text-dark mb-8 text-lg font-normal tracking-wide sm:text-xl md:text-base xl:mb-16 xl:text-xl">
            Picture tricks at your fingertips!
          </h4>
          <Link
            href="/register"
            className="button-large button-primary bg-flash animate-flash sm:button-very-large md:button-large xl:button-very-large mx-8 w-full rounded-full sm:rounded-full"
          >
            Create your free account
          </Link>
          <span className="text-link-text-hover dark:text-link-text-dark-hover text-xs sm:text-base md:text-xs xl:text-base">
            and enjoy {NUM_FREE_DAILY_CREDITS} free image generations every day!
          </span>
          <Link
            href="/login"
            className="button-small sm:button-large button-default-outline md:button-small xl:button-large mt-8 rounded-full sm:rounded-full xl:mt-16"
          >
            Or sign in if you already have an account
          </Link>
        </div>
        <div className="relative h-[275px] w-[300px] sm:h-[550px] sm:w-[600px] md:h-[275px] md:w-[300px] xl:h-[550px] xl:w-[600px]">
          <h3 className={`${ooohBaby.className} absolute inset-x-0 -top-2 flex justify-center pt-2 sm:text-3xl md:text-base xl:text-3xl`}>
            Quality AI models made simple!
          </h3>
          <div className="flex flex-col">
            <div className="absolute right-0 top-8 flex rotate-1 flex-col items-center sm:right-0 sm:top-16 md:right-0 md:top-8 xl:right-0 xl:top-16">
              <span className={`${ooohBaby.className} mr-8 sm:text-3xl md:text-base xl:text-3xl`}>Anime</span>
              <div className="flex items-center">
                <Image
                  src="/images/examples/anime-9-21-thumb.png"
                  alt="SVG"
                  width={176}
                  height={400}
                  className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark h-[200px] w-[88px] rounded-xl border sm:h-[400px] sm:w-[176px] md:h-[200px] md:w-[88px] xl:h-[400px] xl:w-[176px]"
                />
                <span
                  className={`${ooohBaby.className} [writing-mode:vertical-lr] sm:ml-2 sm:text-3xl md:ml-0 md:text-base xl:ml-2 xl:text-3xl`}
                >
                  Many Sizes / Aspect Ratios
                </span>
              </div>
            </div>
            <div className="absolute left-4 top-8 flex -rotate-6 flex-col items-center sm:left-8 sm:top-16 md:left-4 md:top-8 xl:left-8 xl:top-16">
              <span className={`${ooohBaby.className} sm:text-3xl md:text-base xl:text-3xl`}>Epic</span>
              <Image
                src="/images/examples/dreamscape-thumb.png"
                alt="Realistic"
                width={200}
                height={200}
                className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark size-[100px] rounded-xl border sm:size-[200px] md:size-[100px] xl:size-[200px]"
              />
            </div>
            <div className="absolute right-24 top-8 flex rotate-6 flex-col items-center sm:right-44 sm:top-16 md:right-24 md:top-8 xl:right-44 xl:top-16">
              <span className={`${ooohBaby.className} sm:text-3xl md:text-base xl:text-3xl`}>Realistic</span>
              <Image
                src="/images/examples/glamour-thumb.png"
                alt="SVG"
                width={200}
                height={200}
                className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark size-[100px] rounded-xl border sm:size-[200px] md:size-[100px] xl:size-[200px]"
              />
            </div>
            <div className="absolute bottom-8 right-28 flex -rotate-2 flex-col items-center sm:bottom-16 sm:right-52 md:bottom-8 md:right-28 xl:bottom-16 xl:right-52">
              <Image
                src="/images/examples/pixar-svg-thumb.png"
                alt="SVG"
                width={200}
                height={200}
                className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark size-[100px] rounded-xl border sm:size-[200px] md:size-[100px] xl:size-[200px]"
              />
              <span className={`${ooohBaby.className} sm:text-3xl md:text-base xl:text-3xl`}>Color SVG&apos;s</span>
            </div>
            <div className="absolute bottom-8 left-0 flex rotate-6 flex-col items-center sm:bottom-16 sm:left-0 md:bottom-8 md:left-0 xl:bottom-16 xl:left-0">
              <Image
                src="/images/examples/dripping-thumb.png"
                alt="SVG"
                width={200}
                height={200}
                className="drop-shadow-glow dark:drop-shadow-glow-dark border-border dark:border-border-dark size-[100px] rounded-xl border sm:size-[200px] md:size-[100px] xl:size-[200px]"
              />
              <span className={`${ooohBaby.className} sm:text-3xl md:text-base xl:text-3xl`}>Typography</span>
            </div>
          </div>
          <div
            className={`${ooohBaby.className} absolute inset-x-0 -bottom-2 flex justify-center pt-2 sm:text-3xl md:text-base xl:text-3xl`}
          >
            ... and much more!
          </div>
        </div>
      </div>
      <div>{/* <Carousel /> */}</div>
      <div className="mt-8">
        <Styles />
      </div>
      <div className="mt-8">
        <ImageGuides />
      </div>
      <div>
        <Cta bgDark />
      </div>
    </div>
  )
}
