'use client'

import * as React from 'react'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import { useAppSelector } from '@/lib/store'
import { getStyles } from '@/lib/features/styles/stylesSlice'
import { getStyleTypes } from '@/lib/features/styleTypes/styleTypesSlice'
import { STYLE_NAME, STYLE_TYPE_NAME } from '@/lib/constants'
import { EnumStyle, type EnumStyleType } from '../../../../../../../../../lib/database/lib/generated/enums'

export default function HomePageStyles(): React.ReactElement {
  const styles = useAppSelector(getStyles)
  const styleTypes = useAppSelector(getStyleTypes)

  return (
    <section className="bg-neutral-100 pt-16 dark:bg-neutral-900">
      <div className="mx-auto flex w-full max-w-screen-xl flex-col justify-center">
        <div className="mx-4 mb-12 flex flex-col items-center sm:mx-8 md:mx-12 lg:mx-20 xl:mx-32">
          <h3 className="text-primary-text dark:text-primary-text-dark mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-3xl lg:text-4xl">
            100+ styles you can generate
          </h3>
          <h4 className="text-secondary-text sm:text-md dark:text-secondary-text-dark mb-4 text-sm font-normal tracking-wide md:text-lg lg:text-xl">
            The perfect style for every need
          </h4>
          <span className="text-disabled-text dark:text-disabled-text-dark mb-8 text-[10px] font-normal tracking-wide">
            &laquo; The following images have all been generated using our models &raquo;
          </span>
        </div>
        <div className="mb-12">
          <div className="flex w-full flex-wrap justify-center gap-4 px-4">
            {[...(styles ?? [])]
              .sort((styleA, styleB) => (styleA.code === EnumStyle.NONE ? 1 : styleA.sort_order - styleB.sort_order))
              .map((style) => {
                return (
                  <div key={`style_${style.code}`} className="relative size-56 rounded">
                    <ReactCompareSlider
                      itemOne={
                        <>
                          <ReactCompareSliderImage
                            src={style.pixels_example_image}
                            alt={`${STYLE_TYPE_NAME[styleTypes?.[0]?.code as keyof typeof EnumStyleType]} ${STYLE_NAME[style.code as keyof typeof EnumStyle]}`}
                          />
                          <span
                            className={`absolute bottom-2 left-2 rounded-full bg-black/25 px-2.5 py-0.5 text-xs font-medium text-white backdrop-blur after:content-['Pixels']`}
                          />
                        </>
                      }
                      itemTwo={
                        <>
                          <ReactCompareSliderImage
                            src={style.svg_example_image_as_png}
                            alt={`${STYLE_TYPE_NAME[styleTypes?.[1]?.code as keyof typeof EnumStyleType]} ${STYLE_NAME[style.code as keyof typeof EnumStyle]}`}
                          />
                          <span
                            className={`absolute bottom-2 right-2 rounded-full bg-black/25 px-2.5 py-0.5 text-xs font-medium text-white backdrop-blur after:content-['SVG']`}
                          />
                        </>
                      }
                      handle={
                        <div className="relative flex h-full items-center">
                          <div className="absolute left-1/2 top-0 h-full w-0.5 -translate-x-1/2 bg-white" />
                          <div className="border-border flex items-center justify-center rounded-full border bg-white p-0.5">
                            <svg
                              className="text-disabled-text size-6 rotate-90"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.832 3.445a1 1 0 0 0-1.664 0l-4 6A1 1 0 0 0 8 11h8a1 1 0 0 0 .832-1.555l-4-6Zm-1.664 17.11a1 1 0 0 0 1.664 0l4-6A1 1 0 0 0 16 13H8a1 1 0 0 0-.832 1.555l4 6Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      }
                      className="rounded-lg"
                    />
                    {style.code !== EnumStyle.NONE && (
                      <div className="border-border absolute left-0 top-0 w-full rounded-t-lg border-b bg-black/35 px-2.5 py-1 text-center text-xs font-black tracking-widest text-white backdrop-blur">
                        <span>{STYLE_NAME[style.code as keyof typeof EnumStyle]}</span>
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}
